<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                Account Manager
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
                <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
              </v-flex>
              <!-- <button @click="excel()">EXCEL</button> -->
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="regDate"
            hide-actions
            :headers="headers"
            :items="members"
            :search="search"
            :pagination="pagination"
            @filtered="onFiltered"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.hit }}</td>
                <td style="text-align: center;">{{ props.item.regDate }}</td>                
                <td>{{ props.item.userName }}</td>
                <td>{{ props.item.email }}</td>
                <td>{{ props.item.position }}</td>
                <td>{{ props.item.office }}</td>
                <td>{{ props.item.approvalName }}</td>
                <td>{{ props.item.permitName }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>
    </div>

    <j-form-modal
      title="User"
      ref="formHandler"
      @create="add()"
      @edit="edit()"
      @delete="del()"
      @cancel="cancel()"
      :formMode="formode"
      :resetable="resetable()"
      :opened.sync="modOpen"
      class="type01"
    >
      <v-container>
        <v-layout wrap>
          <v-flex sm7>
            <v-flex xs12 sm8>
              <div class="select-box-group field">
                <v-text-field
                  v-model="selectedItem.userName"
                  required
                  label="User Name"
                  placeholder="Input/Edit User Name"
                  :rules="defaultRules"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.email"
                label="E-mail"
                placeholder="Input/Edit email"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.position"
                label="Position"
                placeholder="Input/Edit a Position"
              ></v-text-field>
            </v-flex>
          </v-flex>
          <v-flex sm5>
            <!-- <v-flex xs12>
              <v-text-field
                v-model="selectedItem.id"
                required
                label="User ID"
                placeholder="Input/Edit User ID"
                :rules="defaultRules"
              ></v-text-field>
            </v-flex> -->
            <v-flex xs12 v-if="pwd">
              <v-text-field
                v-model="selectedItem.password"
                required
                label="Password"
                placeholder="Input password"
                :rules="passwordRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.office"
                label="office"
                placeholder="Input/Edit a office"
              ></v-text-field>
            </v-flex>
          </v-flex>
          <v-flex xs12 class="wrap__option_group">
            <span class="label">Approval</span>
            <div class="option_group">
              <label :for="option.text" v-for="option in approvalOptions" :key="option.index">
                <input
                  v-model="selectedItem.approval"
                  type="radio"
                  name="approval"
                  :id="option.text"
                  :value="option.value"
                  :checked="selectedItem.approval == option.value"
                />
                {{ option.text }}
              </label>
            </div>
          </v-flex>
          <v-flex xs12 class="wrap__multiselect">
            <v-select
              v-model="userPermit"
              multiple
              return-object
              item-text="name"
              label="User Permission"
              placeholder="Select permissions to apply"
              :items="permitOptions"
              :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
            ></v-select>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="selectedItem.comment"
              label="Comment"
              placeholder="Input/Edit comment"
            ></v-text-field>
          </v-flex>

          <v-flex v-if="formode=='MOD'" xs12 sm6 class="disabled wrap_readonly">
            <span>Reg. Date</span>
            <div>{{selectedItem.regDate}}</div>
          </v-flex>
          <v-flex v-if="formode=='MOD'" xs12 sm6 class="disabled wrap_readonly">
            <span>Hit</span>
            <div>{{selectedItem.hit}}</div>
          </v-flex>
        </v-layout>
      </v-container>
    </j-form-modal>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { AdminService, SystemCodeService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"
import { mapState } from 'vuex'
export default {
  name: "svcaccess-user-account",
  components: {
    JFormModal
  },
  data: () => ({
    adminService: null,
    systemCodeService: null,
    modOpen: false,
    picPopoverShow: false,
    positoinOptions: [],
    deptOptions: [],
    approvalOptions: [],
    permitOptions: [],
    userPermit: [],
    search: "",
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    pagination: { descending: true, sortBy: 'regDate', page: 1, rowsPerPage: -1, totalItems: 0  },
    headers: [
      {
        type: "conf",
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        width: 50
      },
      {
        type: "text",
        text: "ID",
        align: "left",
        sortable: true,
        value: "id",
        width: 120
      },
      {
        type: "text",
        text: "Hit",
        align: "left",
        sortable: true,
        value: "hit",
        width: 50
      },       
      {
        type: "text",
        text: "Reg. Date",
        align: "center",
        sortable: true,
        value: "regDate",
        width: 80
      },     
      {
        type: "text",
        text: "Name",
        align: "left",
        sortable: true,
        value: "userName",
        width: 120
      },
      {
        type: "text",
        text: "E-mail",
        align: "left",
        sortable: true,
        value: "email",
        width: 120
      },
      {
        type: "text",
        text: "Position",
        align: "left",
        sortable: true,
        value: "position",
        width: 120
      },
      {
        type: "text",
        text: "office",
        align: "left",
        sortable: true,
        value: "office",
        width: 120
      },
      {
        type: "text",
        text: "Approval",
        align: "left",
        sortable: true,
        value: "approvalName",
        width: 100
      },
      {
        type: "text",
        text: "Permission",
        align: "left",
        sortable: true,
        value: "permitName",
        width: 200
      },
    ],
    pwd: false,
    members: [],
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    defaultRules: [v => !!v || "Required"],
    nameRules: [
      v => !!v || "Required",
      v => (!!v && v.length >= 8) || "At least 8 characters",
      v => (!!v && v.length <= 30) || "Less then 20 characters",
      v =>
        (!!v && /^[a-zA-Z0-9\s]*$/.test(v)) || "Not allowed special characters"
    ],
    idRules: [
      v => !!v || "Required",
      v => (!!v && v.length >= 5) || "At least 5 characters",
      v => (!!v && v.length <= 20) || "Less then 20 characters",
      v =>
        (!!v && /^[a-zA-Z0-9_.]*$/.test(v)) ||
        "Not allowed Special characters, except _ and ."
    ],
    passwordRules: [
        v => !!v || 'Required',
        v => (!!v && v.length >= 14) || 'At leat 14 characters required',
        v => /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{14,25}$/.test(v) 
              || '14-25 characters required (at least one number,letter,special char(!@#$%^&*?_)',
    ],
    emailRules: [
      v => !!v || "",
      v => /^[^\s@]+@[^\s@]+[^\s@]+$/.test(v) || "Invalid Email address"
    ],
    pathRules: [
      v => (!!v && /^[a-zA-Z0-9@/]*$/.test(v)) || "Not allowed, except @ /"
    ],
    phoneNumRules: [
      v =>
        (!!v && /^[0-9-\s()+]*$/.test(v)) ||
        'Number, space, "+" and "-" allowed'
    ],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => { }
  }),
  watch: {
    userPermit(val) {
      let userPermits = [];
      val.forEach(m => {
        userPermits.push(m.code);
      });
      this.selectedItem.userPermit = userPermits.join(",");
    },
    codePropagated: {
      handler(val) {
        this.adminService.getBPMembers(val.code, this.setMembers);
      },
      deep: true
    }
  },
  created() {
    this.adminService = new AdminService()
    this.systemCodeService = new SystemCodeService()
    // this.Rules.push(v => this.isCodeValid(v) || 'Duplicated')
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    codePropagated: {
      get() {
        return this.$store.state.sysenv.codePropagated;
      },
      set(value) {
        this.$store.commit("sysenv/codePropagate", value);
      },
    }
  },
  mounted() {
    // to avoid 'Access-Control-Allow-Origin' CORS policy error
    setTimeout(() => {
      this.systemCodeService.permissionGroup(res => {
        if (!res) res = [];
        res.forEach((d,k) => {
          if (d.code == "SYS_ADMIN") res.splice(k,1)
        })
        this.permitOptions = res;

        this.systemCodeService.position(res => {
          if (!res) res = [];
          this.positoinOptions = res;
          this.systemCodeService.department(res => {
            if (!res) res = [];
            this.deptOptions = res;
            this.systemCodeService.userApproval(res => {
              if (!res) this.approvalOptions = [];
              else
                res.forEach(item => {
                  this.approvalOptions.push({
                    text: item.name,
                    value: item.code
                  });
                });

                // if (['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit)) {
                if (this.account.userPermit.split(',').includes('SYS_ADMIN') || this.account.userPermit.split(',').includes('SVC_ADMIN')) {
                this.adminService.getBPMembers(
                  'ALL',
                  this.setMembers
                );
              }
            });
          });
        });
      });
    }, 200);
  },
  methods: {
    setMembers(res) {
      if (!res) res = [];
      this.members = res;
      this.modOpen = false;
    },
    onFiltered(items) {
      // Renumbering for the action of Drag & Drop and search filtering.
      let reNumbering = 0;
      items.forEach(_item => {
        this.members.find(_item_ => _item_.id == _item.id).no = ++reNumbering;
      });
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1;
      });
    },
    add() {
      let equal = this.members.find(d => d.email == this.selectedItem.email)

      if (equal) {
        this.yes = () => {
          this.yes = () => { };
        };
        this.msgInfo.type = "WARN";
        this.msgInfo.title = "Email exist";
        this.msgInfo.titleDescription = "";
        this.msgInfo.message = "Can Not Save";
        this.msgInfo.button = [false, false, true];
        this.msgOpen = true;         
      }else {
        this.yes = async () => {
          this.selectedItem.position = this.selectedItem.position != undefined ? this.selectedItem.position : "WORKER"
          this.selectedItem.userPermit = this.selectedItem.userPermit != "" ? this.selectedItem.userPermit : "WORKER"
          this.selectedItem.id = this.selectedItem.email
          this.msgOpen = false

          // console.log(`this.selectedItem: %s`, JSON.stringify(this.selectedItem))
          let password_org = this.selectedItem.password
          this.selectedItem.password = await this.setPasswordEnc(password_org)

          this.adminService.putAdminMember(this.selectedItem, res => {
          if (['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit)) {
            this.adminService.getBPMembers(
              'ALL',
              this.setMembers
            );
          }
          });
          this.yes = () => { };
        };
        this.msgInfo.type = "INFO";
        this.msgInfo.title = "Save Changes.";
        this.msgInfo.titleDescription = "";
        this.msgInfo.message = "Do you want to add new Member?";
        this.msgInfo.buttonText[0] = "Save";
        this.msgInfo.button = [true, false, true];
        this.msgOpen = true;        
      }
    },
    edit() {
      
      this.yes = () => {
        this.selectedItem.userPermit = this.selectedItem.userPermit != "" ? this.selectedItem.userPermit : "WORKER"
        this.selectedItem.id = this.selectedItem.email
        this.msgOpen = false;
        this.adminService.updMember(this.selectedItem, res => {
        if (['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit)) {
          this.adminService.getBPMembers(
            'ALL',
            this.setMembers
          );
        }
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save this changes?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      this.yes = () => {
        this.msgOpen = false;
        this.adminService.delMember(this.selectedItem.sn, res => {
        if (['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit)) {
          this.adminService.getBPMembers(
            'ALL',
            this.setMembers
          );
        }
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "WARN";
      this.msgInfo.title = "Action Approval";
      this.msgInfo.titleDescription = "Important Notification";
      this.msgInfo.message = "Do you want to delete selected Member?";
      this.msgInfo.buttonText[0] = "Delete";
      this.msgOpen = true;
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {};
        this.$refs.formHandler.formReset();
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0]);
      }
    },
    cancel() {
      this.modOpen = false;
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.members.filter(e => {
          return (
            (e.name || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
          );
        });
        this.loading = false;
      }, 500);
    },
    propStatus(props) {
      // this.setEdit()
      this.pwd = false
      this.selectedIndex = this.members.indexOf(props.item);
      this.selectedItem = Object.assign({}, props.item);

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].id === props.item.id) {
          this.modOpen = true;
          return;
        } else {
          let _selected = props.item;
          this.selected = [_selected];
        }
      } else {
        props.selected = !props.selected;
      }
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
      // this.userPermit = this.selectedItem.userPermit.split(',')
      this.modOpen = true;

      this.userPermit = [];
      this.selectedItem.userPermit.split(",").forEach(code => {
        let group = this.permitOptions.find(o => o.code == code);
        if (!!group) this.userPermit.push(group);
      });
    },
    setAdd() {
      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event.
      // so it should be retained to be increased by them until being -1.
      this.pwd = true
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1;
      this.selectedItem = {};
      this.userPermit = this.selectedItem.userPermit
      this.codePropagated.code !== "ALL" ? [this.codePropagated.code] : [];
      this.selected = [];
      this.selectedPrevItem = [];
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.$refs.formHandler.formReset();
      this.modOpen = true;
    },
    setEdit(selectedIndex) {
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign({}, this.navItem[this.selectedIndex]);
      this.selected = [Object.assign({}, this.navItem[this.selectedIndex])];
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (
          !!this.selectedItem.userName ||
          !!this.selectedItem.id ||
          !!this.selectedItem.email ||
          !!this.selectedItem.password ||
          !!this.selectedItem.cellPhone ||
          !!this.selectedItem.position ||
          !!this.selectedItem.office ||
          !!this.selectedItem.approval ||
          !!this.selectedItem.userPermit ||
          !!this.selectedItem.comment
        )
          return true;
        return false;
      } else {
        if (
          this.selectedPrevItem[0].userName != this.selectedItem.userName ||
          this.selectedPrevItem[0].id != this.selectedItem.id ||
          this.selectedPrevItem[0].email != this.selectedItem.email ||
          this.selectedPrevItem[0].password != this.selectedItem.password ||
          this.selectedPrevItem[0].position != this.selectedItem.position ||
          this.selectedPrevItem[0].office != this.selectedItem.office ||
          this.selectedPrevItem[0].approval != this.selectedItem.approval ||
          this.selectedPrevItem[0].userPermit != this.selectedItem.userPermit ||
          this.selectedPrevItem[0].comment != this.selectedItem.comment
        )
          return true;
        return false;
      }
    },
    isCodeValid(id) {
      return !this.members.find(item => item.id == id);
    },
    async setPasswordEnc(password_) {
      // 비밀번호를 SHA-256으로 해싱
      const passwordBuffer = new TextEncoder().encode(password_)
      const hashedPasswordBuffer = await crypto.subtle.digest('SHA-256', passwordBuffer)
      const hashedPassword = this.arrayBufferToHex(hashedPasswordBuffer).toUpperCase()
      return hashedPassword
    },
    // Utility function to convert ArrayBuffer to hex string
    arrayBufferToHex(buffer) {
      return Array.from(new Uint8Array(buffer))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('')
    },
  }
};
</script>
